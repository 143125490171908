import { getHostFromUrl } from "./util";
import {
  COTS_PROD_DOMAINS,
  COTS_TEST_DOMAINS,
  TELIA_SE_PROD_DOMAINS,
  TELIA_SE_TEST_DOMAINS,
  SPECIAL_DOMAINS,
} from "./constants";

export * from "./constants";

export const isCotsTestDomain = (url: string) => COTS_TEST_DOMAINS.includes(getHostFromUrl(url));
export const isCotsProdDomain = (url: string) => COTS_PROD_DOMAINS.includes(getHostFromUrl(url));
export const isTeliaSeTestDomain = (url: string) =>
  TELIA_SE_TEST_DOMAINS.includes(getHostFromUrl(url));
export const isTeliaSeProdDomain = (url: string) =>
  TELIA_SE_PROD_DOMAINS.includes(getHostFromUrl(url));

export const isSpecialDomain = (url: string) => SPECIAL_DOMAINS.includes(url);
